import React, { FC, useContext } from 'react'
import { EnergyEntry as EnergyEntryType } from '../../../@types'
import { EnergyContext } from '../../../context/EnergyContext'
import { useEnergyEntryHook } from '../../../hooks/useEnergyEntryHook'
import { CloseButton } from '../../CloseButton'
import { UsageEntryId } from './UsageEntryId'
import { UsageEntryMonthConsumption } from './UsageEntryMonthConsumption'
import { UsageEntryMonthHeader } from './UsageEntryMonthHeader'
import { UsageEntryMonthPrice } from './UsageEntryMonthPrice'
import { UsageEntrySpecial } from './UsageEntrySpecial'
import { UsageEntryType } from './UsageEntryType'

type EnergyEntryProps = {
    id: number
    entry: EnergyEntryType
    updateEnergyEntry: (id: number, entry: EnergyEntryType, updateRefreshKey?: boolean) => void
}

export const UsageEntry: FC<EnergyEntryProps> = ({ id, entry, updateEnergyEntry }) => {

    const { removeEnergyEntry } = useContext(EnergyContext)

    const { updatedEntry, handleChangeEvent, handleUsageChange } = useEnergyEntryHook({ id, entry, updateEnergyEntry })

    const hideFromStep2 = () => {
        updateEnergyEntry(id, { ...entry, hideFromStep2: true }, true)
    }

    return (
        <div className='my-3 rounded-2xl border border-primary-500 relative'>
            {
                !entry.isLinked
                    ? <CloseButton className='absolute right-0 top-0' onClick={() => removeEnergyEntry(id)} />
                    : !entry.hideFromStep2
                        ? <CloseButton className='absolute right-0 top-0' onClick={hideFromStep2} />
                        : null
            }
            <UsageEntryId id={id} entry={updatedEntry} handleChangeEvent={handleChangeEvent} />
            <UsageEntrySpecial id={id} entry={updatedEntry} handleChangeEvent={handleChangeEvent} />
            <UsageEntryType id={id} entry={updatedEntry} handleChangeEvent={handleChangeEvent} />
            <div className='bg-primary-500 rounded-b-xl usage_entry_wrapper'>
                <div className='usage_entry'>
                    <UsageEntryMonthHeader />
                    <UsageEntryMonthPrice id={id} entry={updatedEntry} handleUsageChange={handleUsageChange} />
                    <UsageEntryMonthConsumption id={id} entry={updatedEntry} handleUsageChange={handleUsageChange} />
                </div>
            </div>
        </div>
    )
}
