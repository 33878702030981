import React, { useContext } from 'react'
import { EnergyContext } from '../../context/EnergyContext'
import { msg } from '../../locale/texts'
import { Button } from '../Button'
import { Step } from '../Step'
import { UsageEntry } from './UsageEntry'
import { EnergyTotal } from './EnergyTotal'

export const Step2 = () => {
  const { entries, addEnergyEntry, updateEnergyEntry } = useContext(EnergyContext)

  const nextStep = {
    msg: msg.links.nextStep3,
    link: '/step3'
  }

  const prevStep = {
    msg: msg.links.backStep1,
    link: '/',
    right: true
  }

  return (
    <Step
      title={msg.step2.title}
      text={msg.step2.text}
      nextStep={nextStep}
      prevStep={prevStep}
    >

      {entries &&
        entries.map((entry, id: number) => {
          return !entry.hideFromStep2
            ? <div key={`step-2-${entry.key}`} >
              <UsageEntry id={id} entry={entry} updateEnergyEntry={updateEnergyEntry} />
            </div>
            : null
        }
        )
      }

      <Button
        className='mb-3'
        onClick={() => addEnergyEntry(false)}>
        {msg.addPoint}
      </Button>

      <EnergyTotal />

    </Step>
  )
}