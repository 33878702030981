import React, { FC, ReactNode } from 'react'

type Step2EntryProps = {
    title?: string
    text?: string
    className?: string
    children: ReactNode
}
export const Step2Entry: FC<Step2EntryProps> = ({ title, text, children, className }) => {

    return <div className={`px-4 py-2 text-white ${className}`}>
        <div className='flex flex-col sm:flex-row gap-x-8'>
            <div className='w-full sm:w-1/6 flex items-center'>
                {title}
            </div>
            <div className='w-full sm:w-4/6 flex items-center pr-4'>
                {children}
            </div>
            <div className='w-full sm:w-1/6 text-sm flex items-center text-white'>
                {text}
            </div>
        </div>
    </div>
}