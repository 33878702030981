import React, { FC } from 'react'
import { usageMonths } from '../../../data';
import { Step2Entry } from '../Step2Entry';

type UsageEntryMonthHeaderType = {}

export const UsageEntryMonthHeader: FC<UsageEntryMonthHeaderType> = () => {

    return <Step2Entry className='border-b border-primary-50'>
        {
            usageMonths.map((usage, id) =>
                <span key={`USAGE_HEADER-${id}`} className={`first:border-none border-l border-primary-50 text-center appearance-none w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline text-sm`}
                >
                    {usage.name} {usage.year}
                </span>
            )
        }
    </Step2Entry>
}