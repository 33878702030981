import React, { FC, ChangeEvent, useState, useEffect } from 'react'
import { EnergyEntryPropEnum, EnergyEntryUsagePropEnum } from '../../../@enums'
import { EnergyEntry, EnergyUsageType } from '../../../@types';
import { msg } from '../../../locale/texts';
import { printNumber } from '../../../utils';
import { Input } from '../../Input';
import { Step2Entry } from '../Step2Entry';

type UsageEntryMonthConsumptionType = {
    entry: EnergyEntry
    id: number
    handleUsageChange: (usages: EnergyUsageType[]) => void
}

export const UsageEntryMonthConsumption: FC<UsageEntryMonthConsumptionType> = ({ entry, id, handleUsageChange }) => {
    const [newUsage, setNewUsage] = useState(entry.usage)
    const disabledInput = !entry.isLinked ? false : entry.special === "false"
    const property = EnergyEntryPropEnum.USAGE
    const usage_property = EnergyEntryUsagePropEnum.CONSUMPTION

    useEffect(() => {
        setNewUsage(entry.usage)
    }, [entry.usage])

    useEffect(() => {
        handleUsageChange(newUsage)
    }, [newUsage])

    const handleChange = (e: ChangeEvent<any>, id: number) => {
        if (e) {
            setNewUsage(oldUsages => {
                let newUsages: EnergyUsageType[] = oldUsages.map(oldUsage => Object.assign({}, oldUsage))
                newUsages[id] = { ...newUsages[id], [e.target.name]: parseFloat(e.target.value) }
                return newUsages
            })
        }
    }

    return <Step2Entry
        title={
            entry.special === 'true' ?
                `${entry.type}${msg.usageEntry.consumption1Yes}` :
                `${msg.usageEntry.consumption1No} ${entry.type}${msg.usageEntry.consumption2No}`
        }
        text={`${entry.special === 'true' ? msg.usageEntry.consumptionTextYes : msg.usageEntry.consumptionTextNo}`}
    >
        {
            newUsage.map((usage, usage_id) =>
                <div
                    key={`${property}[${usage_id}][${usage_property}]`}
                    className={`bg-white rounded  border-slate-400`}
                >
                    <Input
                        key={`${property}[${usage_id}][${usage_property}]`}
                        id={`${property}-${usage_property}-${id}${usage_id}`}
                        disabled={disabledInput}
                        type='number'
                        name={`${usage_property}`}
                        value={
                            usage[usage_property]
                                ? usage[usage_property]
                                : undefined
                        }
                        decimals={0}
                        onChange={(e) => handleChange(e, usage_id)}
                        step={1}
                    />
                </div>
            )
        }
    </Step2Entry>
}