import React, { FC, ChangeEvent, useContext } from 'react'
import { EnergyEntryPropEnum } from '../../../@enums'
import { EnergyEntry } from '../../../@types'
import { EnergyContext } from '../../../context/EnergyContext';
import { msg } from "../../../locale/texts";
import { Entry } from '../../Entry';
import { Select } from '../../Select';

type UsageEntryType = {
    entry: EnergyEntry
    id: number
    handleChangeEvent: (e: ChangeEvent<any>) => void
}
export const UsageEntryType: FC<UsageEntryType> = ({ entry, id, handleChangeEvent }) => {
    const { energyTypes } = useContext(EnergyContext)
    const property = EnergyEntryPropEnum.TYPE

    return <Entry
        title={`${msg.usageEntry.type}`}
        usageEntry
    >
        <Select id={`${property}${id}`} disabled={entry.isLinked} name={property} value={entry[property]} onChange={handleChangeEvent} options={energyTypes} />
    </Entry>
}