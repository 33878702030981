import React, { FC, ChangeEvent } from 'react'
import { EnergyEntryPropEnum } from '../../../@enums'
import { EnergyEntry } from '../../../@types'
import { entrySpecialOptions } from '../../../data';
import { msg } from "../../../locale/texts";
import { Entry } from '../../Entry';
import { Select } from '../../Select';

type UsageEntrySpecialType = {
    entry: EnergyEntry
    id: number
    handleChangeEvent: (e: ChangeEvent<any>) => void
}

export const UsageEntrySpecial: FC<UsageEntrySpecialType> = ({ entry, id, handleChangeEvent }) => {
    const property = EnergyEntryPropEnum.SPECIAL

    return <Entry
        title={`${msg.usageEntry.special}`}
        usageEntry
    >
        <Select id={`${property}${id}`} disabled={entry.isLinked} name={property} value={entry[property]} onChange={handleChangeEvent} options={entrySpecialOptions} />
    </Entry>
}