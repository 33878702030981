import * as React from "react"
import type { HeadFC } from "gatsby"
import { Step2 } from "../components/Step2"


const IndexPage = () => {
    const [mounted, setMounted] = React.useState(false)

    React.useEffect(() => {
        setMounted(true)
    }, [])

    return mounted ? <Step2 /> : null
}

export default IndexPage

export const Head: HeadFC = () => (<title>Home Page</title>)
