import React, { FC, ChangeEvent } from 'react'
import { EnergyEntryPropEnum } from '../../../@enums'
import { EnergyEntry } from '../../../@types'
import { msg } from "../../../locale/texts";
import { Entry } from '../../Entry';
import { Input } from '../../Input';

type UsageEntryIdType = {
    entry: EnergyEntry
    id: number
    handleChangeEvent: (e: ChangeEvent<any>) => void
}
export const UsageEntryId: FC<UsageEntryIdType> = ({ entry, id, handleChangeEvent }) => {
    const property = EnergyEntryPropEnum.ID

    return <Entry
        title={`${msg.usageEntry.id}`}
        usageEntry
    >
        <Input id={`${property}${id}`} disabled={entry.isLinked} name={property} value={entry[property]} onChange={handleChangeEvent} />
    </Entry>
}