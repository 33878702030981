import React, { FC, ChangeEvent, useState, useEffect } from 'react'
import { EnergyEntryPropEnum, EnergyEntryUsagePropEnum } from '../../../@enums';
import { EnergyEntry, EnergyUsageType } from '../../../@types';
import { msg } from '../../../locale/texts';
import { printNumber } from '../../../utils';
import { Input } from '../../Input';
import { Step2Entry } from '../Step2Entry';

type UsageEntryMonthPriceType = {
    entry: EnergyEntry
    id: number
    handleUsageChange: (usages: EnergyUsageType[]) => void
}

export const UsageEntryMonthPrice: FC<UsageEntryMonthPriceType> = ({ entry, id, handleUsageChange }) => {
    const [newUsage, setNewUsage] = useState(entry.usage)
    const property = EnergyEntryPropEnum.USAGE
    const usage_property = EnergyEntryUsagePropEnum.PRICE

    useEffect(() => {
        setNewUsage(entry.usage)
    }, [entry.usage])

    useEffect(() => {
        handleUsageChange(newUsage)
    }, [newUsage])

    const handleChange = (e: ChangeEvent<any>, id: number) => {
        if (e) {
            setNewUsage(oldUsages => {
                let newUsages: EnergyUsageType[] = oldUsages.map(oldUsage => Object.assign({}, oldUsage))
                newUsages[id] = { ...newUsages[id], [e.target.name]: parseFloat(e.target.value) }
                return newUsages
            })
        }
    }

    return <Step2Entry
        title={`${msg.usageEntry.price1} ${entry.type} ${msg.usageEntry.price2}`}
        text={`${entry.type}${msg.usageEntry.priceText}`}
    >
        {
            newUsage.map((usage, usage_id) =>
                <Input
                    key={`${property}[${usage_id}][${usage_property}]`}
                    id={`${property}-${usage_property}-${id}${usage_id}`}
                    type='number'
                    name={`${usage_property}`}
                    value={
                        usage[usage_property]
                            ? usage[usage_property]
                            : undefined
                    }
                    decimals={4}
                    onChange={(e) => handleChange(e, usage_id)}
                    step={0.0001}
                />
            )
        }
    </Step2Entry>
}